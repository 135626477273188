<template>
  <div class="page-web page-bg">
    <Head />
    <div class="contract-section">
      <div class="container">
        <div class="contract-tab">
          <el-tabs v-model="symbol" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.symbol+$t('meun4_3')"
                         :name="item.symbol">
              <div class="contract-tab2">
                <el-tabs v-model="activeName2">
                  <el-tab-pane :label="$t('qcTab1')" name="first">
                    <div class="contract-result">
                      <ul class="result-list clearfix">
                        <li class="result-list-item" v-for="(item2,index) in list" :key="index">
                          <div class="result-list-item__text">
                            {{$t('qcTxt')}}<br>
                            {{item2.optionNo}}<br>
                            {{$t('qcTxt2')}}
                          </div>
                          <el-popover placement="bottom" width="200" trigger="hover"
                                      popper-class="custom-popper">
                            <div class="result-list-item__desc">
                              <p><span>{{$t('qcTxt3')}}</span>：{{item2.openTime | datefmt('YYYY-MM-DD HH:mm')}}
                              </p>
                              <p><span>{{$t('qcTxt4')}}</span>：{{item2.closeTime | datefmt('YYYY-MM-DD HH:mm')}}
                              </p>
                              <p><span>{{$t('qcTxt5')}}</span>：{{item2.openPrice}}
                                {{item.baseSymbol}}
                              </p>
                              <p><span>{{$t('qcTxt6')}}</span>：{{item2.closePrice}}
                                {{item.baseSymbol}}
                              </p>
                              <p><span>{{$t('qcTxt7')}}</span>：{{item2.totalBuy}}
                                {{item.baseSymbol}}
                              </p>
                              <p><span>{{$t('qcTxt8')}}</span>：{{item2.closePrice}}
                                {{item.baseSymbol}}
                              </p>
                            </div>
                            <el-button slot="reference" class="result-list-item__btn">
															<span class="result-icon result-lose"
                                    v-if="item2.result == 'LOSE'">
																<i class="iconfont icon-md-trending-up"></i>
															</span>
                              <span class="result-icon result-win"
                                    v-if="item2.result == 'WIN'">
																<i class="iconfont icon-md-trending-down"></i>
															</span>
                              <span class="result-icon result-tied"
                                    v-if="item2.result == 'TIED'">
																<i class="iconfont icon-md-remove"></i>
															</span>
                            </el-button>
                          </el-popover>
                        </li>
                      </ul>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane :label="$t('qcTab2')" name="second">
                    <div :id="item.symbol+'_kline_container'" style="height: 296px;position: relative;">
                    </div>

                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="contract-panel">
          <div class="panel-head" v-if="openingOption.id === 0">
            <div style="height:40px;line-height:40px;">{{ $t('noopening') }}</div>
          </div>
          <div class="panel-head" v-if="openingOption.id !== 0">
            <div class="contract-issue">{{$t('qcTxt')}}{{openingOption.optionNo}}{{$t('qcTxt2')}}<br></div>
            <div class="contract-amount text-green">
              <div class="contract-amount__label">
                {{$t('qcTxt7')}}：
              </div>
              {{openingOption.totalBuy}} USDT
            </div>
            <div class="contract-amount text-red">
              <div class="contract-amount__label">{{$t('qcTxt8')}}：</div>
              {{openingOption.totalSell}} USDT
            </div>
            <div class="contract-amount text-orange" v-if="isLogin">
              {{$t('qcTxt9')}}：{{myOpeningOption.betAmount}} USDT
            </div>
            <div class="contract-amount text-orange" v-if="!isLogin">
              {{ $t('qcTxt9') }}：
              <router-link to="/login">{{ $t('meun8') }}</router-link>/<router-link to="/register">
              {{ $t('meun9') }}
            </router-link>
            </div>
            <div class="contract-progress">
              {{$t('qcTxt10')}}：
              <div class="contract-progress-bar">
                <el-progress :percentage="currentPercent" :stroke-width="17" :show-text="false">
                </el-progress>
              </div>
              <div class="contract-progress-value">{{currentPercent.toFixed(2)}}%</div>
            </div>
            <div class="contract-price">
              <div class="contract-price-item">{{$t('qcTxt11')}}：{{openPrice}} USDT</div>
              <div class="contract-price-item">{{$t('qcTxt12')}}：<span
                  :class="{ 'text-green': currentPrice > openPrice, 'text-red': currentPrice < openPrice }">{{currentPrice}}USDT</span>
              </div>
            </div>
            <div class="contract-rate"
                 :class="{'bg-green': currentPrice > openPrice, 'bg-red': currentPrice < openPrice }">
              {{priceChange}}
            </div>
          </div>
          <div class="panel-body">
            <div class="contract-content">
              <div class="contract-title">{{$t('qcTxt')}} {{startingOption.optionNo}} {{$t('qcTxt2')}}
              </div>
              <div class="contract-range">{{$t('qcTxt13')}}：
                {{ (startingOption.createTime + currentCoin.openTimeGap * 1000) | datefmt('YYYY-MM-DD HH:mm')}}
                ~
                {{(startingOption.createTime + currentCoin.openTimeGap * 1000 + currentCoin.closeTimeGap * 1000) | datefmt('YYYY-MM-DD HH:mm')}}
              </div>
              <div class="contract-data">
                <el-row>
                  <el-col :span="8">
                    <div class="contract-data-item text-green">
                      <div class="contract-data-item__value">{{startingOption.totalBuy}} USDT
                      </div>
                      <div class="contract-data-item__label">{{$t('qcTxt7')}}</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="contract-data-item text-red">
                      <div class="contract-data-item__value">{{startingOption.totalSell}} USDT
                      </div>
                      <div class="contract-data-item__label">{{$t('qcTxt8')}}</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="contract-data-item text-orange">
                      <div class="contract-data-item__value">{{myStartingOption.betAmount}} USDT
                      </div>
                      <div class="contract-data-item__label">{{$t('qcTxt9')}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="contract-usd">
              <el-radio-group v-model="currentSelectCount">
                <el-radio-button :label="item" v-for="item in currentCoinAmountList" :key="item">
                  {{item}} USDT
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="contract-balance">
              {{$t('czTxt4')}}：{{assetUsdt}} USDT
            </div>
            <div class="contract-foot">
              <el-button v-if="isLogin" type="success" @click="addClick(0)">{{$t('qcbtn1')}}</el-button>
              <el-button v-if="isLogin" type="danger" @click="addClick(1)">{{$t('qcbtn2')}}</el-button>
              <el-button v-if="!isLogin" type="info" disabled>{{$t('loginFirst')}}</el-button>
              <el-button v-if="!isLogin" type="info" disabled>{{$t('loginFirst')}}</el-button>
            </div>
          </div>
        </div>
        <div class="contract-table">
          <el-table :data="orders" style="width: 100%">
            <el-table-column prop="periods" :label="$t('qcth1')">
              <template slot-scope="scope">
								<span class="text-info">
									{{ scope.row.symbol }} - {{$t('qcTxt')}}{{ scope.row.optionNo }} {{$t('qcTxt2')}}
								</span>
              </template>
            </el-table-column>
            <el-table-column prop="betAmount" align="center" :label="$t('qcth2')">
            </el-table-column>
            <el-table-column prop="direction" align="center" :label="$t('qcth3')">
              <template slot-scope="scope">
                <span class="text-green" v-if="scope.row.direction == 'BUY'">{{ $t('qcbtn1') }}</span>
                <span class="text-red" v-else>{{ $t('qcbtn2') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="result" align="center" :label="$t('qcth4')">
              <template slot-scope="scope">
                <span class="text-green" v-if="scope.row.result == 'WIN'">{{ $t('win') }}</span>
                <span class="text-green" v-else-if="scope.row.result == 'WAIT'">{{ $t('labTab3') }}</span>
                <span class="text-red" v-else>{{ $t('lose') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="rewardAmount" align="center" :label="$t('qcth5')">
              <template slot-scope="scope">
                <span class="text-green" v-if="scope.row.result == 'WAIT'">-</span>
                <span class="text-red" v-else>
									<span v-if="scope.row.rewardAmount > 0">+{{ scope.row.rewardAmount }}</span>
								</span>
              </template>
            </el-table-column>
            <el-table-column prop="fee" align="center" :label="$t('qcth6')">
              <template slot-scope="scope">
                <span v-if="scope.row.result == 'WAIT'">-</span>
                <span v-else>{{ scope.row.fee }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="winFee" align="center" :label="$t('qcth7')">
              <template slot-scope="scope">
                <span v-if="scope.row.result == 'WAIT'">-</span>
                <span v-else>{{ scope.row.winFee }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" align="center" :label="$t('apith2')">
              <template slot-scope="scope">
                <span>{{scope.row.createTime | datefmt('YYYY-MM-DD HH:mm')}}</span>
              </template>
            </el-table-column>
            <template slot="empty" class="exte_empty">
              <p>{{$t('czEmpty')}}</p>
            </template>
          </el-table>
        </div>
        <div class="exte-box">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
                         layout="prev, pager, next" :total="total" :background="true"></el-pagination>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import {
  coinList,
  optionHistory,
  optionOpen,
  optionStart,
  optionCoinInfo,
  optionCurrent,
  optionOrderHistory,
  optionOrderAdd
} from '@/api/api/option'
import {
  wallet
} from '@/api/api/user'
// @ is an alias to /src
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import Datafeeds from '@/assets/js/charting_library/datafeed/optiontrade.js'
import $ from '@/assets/js/jquery.min'
export default {
  name: 'Home',
  components: {
    Head,
    Foot
  },
  data() {
    return {
      activeName2: 'first',
      orders: [],
      currentSelectCount: '',
      radio1: 'Time',
      tabList: [],
      list: [],
      count: 40,
      symbol: 'BTC/USDT',
      openingOption: {
        id: 0,
        optionNo: '-',
        totalBuy: 0,
        totalSell: 0,
      },
      startingOption: {
        optionNo: '-',
        totalBuy: 0,
        totalSell: 0,
      },
      myOpeningOption: {
        betAmount: 0
      },
      myStartingOption: {
        betAmount: 0,
      },
      currentCoin: {},
      isLogin: false,
      tableMoney: [],
      assetUsdt: 0,
      currentPercent: 0,
      openPrice: 0,
      currentPrice: 0,
      currentCoinAmountList: [],
      currentPage: 1,
      total: 10,
      pageSize: 10
    };
  },
  computed: {
    priceChange: function() {
      var chg = this.currentPrice - this.openPrice
      var percent = (chg / this.openPrice) * 100
      if (chg > 0) {
        return '+' + percent.toFixed(3) + '%'
      }
      if (chg < 0) {
        return percent.toFixed(3) + '%'
      }
      return '0.00%'
    },
  },
  created() {
    this.isLogin = this.$store.state.isLogin
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.isLogin) {
        this.getAssets()
      }
      this.setTime()
      this.getCoinList()
      this.getCoinInfo()
      this.initPageData()
      this.loadMyOrders()
      this.startKlineWebsock()
    },
    initPageData() {
      this.getHistory()
      this.getOpening()
      this.getStarting()
      this.loadMyOrders()
    },

    startKlineWebsock() {
      if (this.stompClientK) {
        this.stompClientK.ws.close()
      }
      var stompClientK = null
      var that = this
      var socket = new SockJS(that.host + '/option/option-ws')
      stompClientK = Stomp.over(socket)
      this.stompClientK = stompClientK
      stompClientK.debug = false

      stompClientK.connect({}, function(frame) {
        that.datafeedK = new Datafeeds.WebsockFeed(
            that.host + '/swap',
            that.currentCoin,
            stompClientK,
            that.baseCoinScale
        )
        that.getKline()
      })
    },
    setTime() {
      // 1s
      var that = this
      setInterval(function() {
        if (
            that.openingOption === undefined ||
            that.openingOption.openTime === undefined ||
            that.openingOption.openTime === 0 ||
            that.openingOption.openTime == null
        ) {
          that.getOpening()
          return
        }
        var currentTime = new Date().getTime()
        var cp = Number(((currentTime - that.openingOption.openTime) / that.currentCoin.closeTimeGap /
            10).toFixed(2))
        if (cp >= 100) {
          that.currentPercent = 100
        } else {
          that.currentPercent = cp
        }
        if (that.currentPercent >= 100) {
          that.initPageData()
        }
      }, 1000)
    },
    getAssets() {
      wallet().then(res => {
        if (res.code == 0) {
          this.tableMoney = res.data
          for (let i = 0; i < this.tableMoney.length; i++) {
            if (this.tableMoney[i].coin.unit == 'USDT') {
              this.assetUsdt = this.tableMoney[i].balance
            }
          }
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    getHistory() {
      optionHistory({
        symbol: this.symbol,
        count: this.count
      }).then(res => {
        if (res.code == 0) {
          this.list = res.data.content.reverse()
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    getOpening() {
      optionOpen({
        symbol: this.symbol
      }).then(res => {
        if (res.code == 0) {
          this.openingOption = res.data[0]
          this.openPrice = this.openingOption.openPrice
          this.getMyOpeningOptionOrder(this.openingOption.id)
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    getStarting() {
      optionStart({
        symbol: this.symbol
      }).then(res => {
        if (res.code == 0) {
          this.startingOption = res.data[0]
          this.getMyStartingOptionOrder(this.startingOption.id) // 获取正在投注的我的订单
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    getCoinList() {
      coinList().then(res => {
        if (res.code == 0) {
          this.tabList = res.data
        }
      })
    },
    getMyOpeningOptionOrder(optionId) {
      this.myOpeningOption = {
        betAmount: 0
      }
      if (this.isLogin) {
        let params = {}
        params.symbol = this.symbol
        params.optionId = optionId


        optionCurrent(params).then(res => {
          if (res.code == 0) {
            if (res.data.length > 0) {
              this.myOpeningOption = res.data[0]
            }
          } else {
            this.$notify.error({
              title: this.$t('apiTxt2'),
              message: res.message,
            })
          }
        })
      }
    },
    getMyStartingOptionOrder(optionId) {
      this.myStartingOption = {
        betAmount: 0
      }
      if (this.isLogin) {
        let params = {}
        params.symbol = this.symbol
        params.optionId = optionId


        optionCurrent(params).then(res => {
          if (res.code == 0) {
            if (res.data.length > 0) {
              this.myStartingOption = res.data[0]
            }
          } else {
            this.$notify.error({
              title: this.$t('apiTxt2'),
              message: res.message,
            })
          }
        })
      }
    },
    getCoinInfo() {
      optionCoinInfo({
        symbol: this.symbol
      }).then(res => {
        if (res.code == 0) {
          this.currentCoin = res.data
          this.currentCoinAmountList = this.currentCoin.amount.split(',')
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    handleClick(tab) {
      this.symbol = tab.name
      this.currentPrice = this.openPrice
      this.getCoinInfo()
      this.initPageData()
      console.log(this.symbol)
      this.startKlineWebsock()
    },
    loadMyOrders() {
      if (!this.isLogin) {
        return
      }
      optionOrderHistory({
        symbol: this.symbol,
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code == 0) {
          if (res.data.content.length > 0) {
            this.total = res.data.totalElements
            this.orders = res.data.content
          }
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    handleCurrentChange(value) {
      this.currentPage = value
      this.loadMyOrders()
    },
    addClick(direction) {
      if (this.currentSelectCount == 0) {
        this.$message.error(this.$t('selectAmount'))
        return
      }
      if (this.currentSelectCount > this.assetUsdt) {
        this.$message.error(this.$t('balancenotenough'))
        return
      }
      let params = {}
      params.symbol = this.symbol
      params.direction = direction
      params.optionId = this.startingOption.id
      params.amount = this.currentSelectCount
      optionOrderAdd(params).then(res => {
        if (res.code == 0) {
          this.getStarting()
          this.getAssets()
          this.loadMyOrders()
          this.$message.success(this.$t('betsuccess'))
        } else {
          this.$notify.error({
            title: this.$t('apiTxt2'),
            message: res.message,
          })
        }
      })
    },
    getKline() {
      var that = this
      let config = {
        autosize: true,
        height: 296,
        fullscreen: false,
        symbol: that.symbol,
        interval: '1', // 默认K线周期
        timezone: 'Asia/Shanghai',
        toolbar_bg: '#0b1520',
        container_id: that.symbol+'_kline_container',
        datafeed: that.datafeedK,
        library_path: '/charting_library/',
        locale: 'zh',
        debug: false,
        drawings_access: {
          type: 'black',
          tools: [{ name: 'Regression Trend' }],
        },
        disabled_features: [
          'volume_force_overlay',
          'header_resolutions',
          'timeframes_toolbar',
          'header_symbol_search',
          'header_chart_type',
          'header_compare',
          'header_undo_redo',
          'header_screenshot',
          'header_saveload',
          //"use_localstorage_for_settings",
          //"left_toolbar",
          'volume_force_overlay',
          'widget_logo',
          'compare_symbol',
          'display_market_status',
          'go_to_date',
          'header_interval_dialog_button',
          'legend_context_menu',
          'show_hide_button_in_legend',
          'show_interval_dialog_on_key_press',
          'snapshot_trading_drawings',
          'symbol_info',
          //"header_widget",
          'edit_buttons_in_legend',
          'context_menus',
          'control_bar',
          'border_around_the_chart',
        ],
        enabled_features: [
          'disable_resolution_rebuild',
          'keep_left_toolbar_visible_on_small_screens', //防止左侧工具栏在小屏幕上消失
          'hide_last_na_study_output',
          'move_logo_to_main_pane',
          'dont_show_boolean_study_arguments',
          'use_localstorage_for_settings',
          'remove_library_container_border',
          'save_chart_properties_to_local_storage',
          'side_toolbar_in_fullscreen_mode',
          'constraint_dialogs_movement',
          'hide_left_toolbar_by_default',
          'left_toolbar',
          'same_data_requery',
          'header_in_fullscreen_mode',
        ],
        //成交量样式
        studies_overrides: {
          'volume.volume.color.0': '#f15057',
          'volume.volume.color.1': '#00b275',
          'volume.volume.transparency': 25,
        },
        custom_css_url: 'bundles/common_option.css',
        supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
        charts_storage_url: 'http://saveload.tradingview.com',
        charts_storage_api_version: '1.1',
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        overrides: {
          'paneProperties.background': '#0b1520',
          'paneProperties.vertGridProperties.color': 'rgba(0,0,0,.1)',
          'paneProperties.horzGridProperties.color': 'rgba(0,0,0,.1)',
          //"scalesProperties.textColor" : "#AAA",
          'scalesProperties.textColor': '#61688A',
          'mainSeriesProperties.candleStyle.upColor': '#00b275',
          'mainSeriesProperties.candleStyle.downColor': '#f15057',
          'mainSeriesProperties.candleStyle.drawBorder': false,
          'mainSeriesProperties.candleStyle.wickUpColor': '#589065',
          'mainSeriesProperties.candleStyle.wickDownColor': '#AE4E54',
          'paneProperties.legendProperties.showLegend': false,

          'mainSeriesProperties.areaStyle.color1': 'rgba(71, 78, 112, 0.5)',
          'mainSeriesProperties.areaStyle.color2': 'rgba(71, 78, 112, 0.5)',
          'mainSeriesProperties.areaStyle.linecolor': '#9194a4',
          volumePaneSize: 'small',
        },
        time_frames: [
          { text: '1min', resolution: '1', description: 'realtime', title: 'Timer' },
          { text: '1min', resolution: '1', description: '1min' },
          { text: '5min', resolution: '5', description: '5min' },
          { text: '15min', resolution: '15', description: '15min' },
          { text: '30min', resolution: '30', description: '30min' },
          { text: '1hour', resolution: '60', description: '1hour', title: '1hour' },
          { text: '4hour', resolution: '240', description: '4hour', title: '4hour' },
          { text: '1day', resolution: '1D', description: '1day', title: '1day' },
          { text: '1week', resolution: '1W', description: '1week', title: '1week' },
          { text: '1mon', resolution: '1M', description: '1mon' },
        ],
      }
      let theme = localStorage.getItem('theme')
      if (theme === 'white' || theme === null) {
        config.toolbar_bg = '#fff'
        config.custom_css_url = 'bundles/common_day.css'
        config.overrides['paneProperties.background'] = '#fff'
        config.overrides['mainSeriesProperties.candleStyle.upColor'] = '#a6d3a5'
        config.overrides['mainSeriesProperties.candleStyle.downColor'] = '#ffa5a6'
      }
      require(['@/assets/js/charting_library/charting_library.min.js'], function(tv) {
        var widget = (window.tvWidget = new TradingView.widget(config))
        widget.onChartReady(function() {
          widget.chart().executeActionById('drawingToolbarAction')

          if (window.innerWidth > 767) {
            widget
                .createButton()
                .attr('title', 'realtime')
                .on('click', function() {
                  if ($(this).hasClass('selected')) return
                  $(this)
                      .addClass('selected')
                      .parent('.group')
                      .siblings('.group')
                      .find('.button.selected')
                      .removeClass('selected')
                  widget.chart().setChartType(2)
                  widget.setSymbol('', '1')
                })
                .append('<span>Time</span>')
          }
          widget
              .createButton()
              .attr('title', 'M1')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '1')
              })
              .append('<span>M1</span>')
              .addClass('selected')

          widget
              .createButton()
              .attr('title', 'M5')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '5')
              })
              .append('<span>M5</span>')
          if (window.innerWidth > 767) {
            widget
                .createButton()
                .attr('title', 'M15')
                .on('click', function() {
                  if ($(this).hasClass('selected')) return
                  $(this)
                      .addClass('selected')
                      .parent('.group')
                      .siblings('.group')
                      .find('.button.selected')
                      .removeClass('selected')
                  widget.chart().setChartType(1)
                  widget.setSymbol('', '15')
                })
                .append('<span>M15</span>')
          }

          widget
              .createButton()
              .attr('title', 'M30')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '30')
              })
              .append('<span>M30</span>')

          widget
              .createButton()
              .attr('title', 'H1')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '60')
              })
              .append('<span>H1</span>')
          if (window.innerWidth > 767) {
            widget
                .createButton()
                .attr('title', 'H4')
                .on('click', function() {
                  if ($(this).hasClass('selected')) return
                  $(this)
                      .addClass('selected')
                      .parent('.group')
                      .siblings('.group')
                      .find('.button.selected')
                      .removeClass('selected')
                  widget.chart().setChartType(1)
                  widget.setSymbol('', '240')
                })
                .append('<span>H4</span>')
          }
          widget
              .createButton()
              .attr('title', 'D1')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '1D')
              })
              .append('<span>D1</span>')

          widget
              .createButton()
              .attr('title', 'W1')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '1W')
              })
              .append('<span>W1</span>')

          widget
              .createButton()
              .attr('title', 'M1')
              .on('click', function() {
                if ($(this).hasClass('selected')) return
                $(this)
                    .addClass('selected')
                    .parent('.group')
                    .siblings('.group')
                    .find('.button.selected')
                    .removeClass('selected')
                widget.chart().setChartType(1)
                widget.setSymbol('', '1M')
              })
              .append('<span>M1</span>')
        })
      })
    }
  }
}
</script>
