import request from '../request'


// 获取所有期权合约交易对列表
export function coinList(data) {
	return request({
		url: '/option/coin/coin-list',
		method: 'POST',
		data: data
	})
}


// 获取历史期数
export function optionHistory(data) {
	return request({
		url: '/option/option/history',
		method: 'POST',
		data: data
	})
}


// 获取正在开奖中的合约
export function optionOpen(data) {
	return request({
		url: '/option/option/opening',
		method: 'POST',
		data: data
	})
}


// 获取正在投注中的合约
export function optionStart(data) {
	return request({
		url: '/option/option/starting',
		method: 'POST',
		data: data
	})
}


// 获取当前币种指定期数ID的参与记录
export function optionCurrent(data) {
	return request({
		url: '/option/order/current',
		method: 'POST',
		data: data
	})
}


// 获取期权合约交易对信息
export function optionCoinInfo(data) {
	return request({
		url: '/option/coin/coin-info',
		method: 'POST',
		data: data
	})
}

// 获取当前币种历史参与记录
export function optionOrderHistory(data) {
	return request({
		url: '/option/order/history',
		method: 'POST',
		data: data
	})
}


// 添加订单
export function optionOrderAdd(data) {
	return request({
		url: '/option/order/add',
		method: 'POST',
		data: data
	})
}